
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexyZTpZxlU7D3h49n__9kDzlrzxWZ3h62hOLFwjv05htQMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/index.vue?macro=true";
import { default as loginhFVSR4yJ2vU_PC_8I8wGnuyemKqx3tQOq8RiGUWu1KgMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifyw9LSwqeO5OOtIf5DWPI71_45zRoK__hqK_45Ihojr8_WT_45AMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemapovjAXw_UgJ97DUymxWuBSDzd8MZlI2ocUMMTrbUmPN8Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45usnhKrC4WelV_wpirTRZ4wixaerIkX7hMu78JNoMPhE8UMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerDT7119TeO11861oZ1_RXKCoCn5sStQ107fZkvMSAfvIMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaintscteQI2xFv_45zUX9_45zffLRf9ysMyTe4W_kn8ADFcZs9U0Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usaFgi_JgOFHP2mUGHatWpzkVLu6FHNe8n01Mp8AoAaNcMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as index2Gs0GRHcNjBsH6EEpcZXIxyggI5vUK6P0VbHyeC7LTQMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indexXMzxgTFW_XuPBBlkfBdHKmOobAZeFflTuvkes_457lv4oMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93xy1i1jH0_WxdS1iVP2UMLrdo_457Bmj8vrQUw5GYIsLCUMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsi2EuLvmQiJwbsiTNG9KpeL8eg5AQpPhHsn2eA1kqDQEMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45useSO2kT6dirHzp62jhbbLr2bK0cx08ZnP0IpA4ckistlEMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as indexLElnIhPSfZvJbkSzL8bESEsOj4CFU2YoJEUTFrCBMWkMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policyS_45WbQKxh3mtPcCvGZVLMgjbffB6_OdGUaWOoH_45W6sQwMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordqfruejslmA6Jd_45a7FsYKHCHSAMFhQPCe17tlgEdNyAYMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexYOlgEu9A3Tkt4SULBdGEsW2iA3tBZ2rqLi1nlCAvstcMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as index7afHpBXT_3_nldUoVq2CShwKYdsyH2Yivw36e_45fPNpYMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordtur_45gAOOFK_XLYaY2d0jFKnoUJ9PIFvWdf9g26yVCkIMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as indexIhaav97OxAsanubgyR6ROAdBT7yG1CP_9ebHcYZ5VtYMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93o_45NXmBqWEYsaaVRDNRmW29P6xWELmLnFOxgmCYz0XEEMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guidesesIEO6GzrjxU_45Vr6BiCfwY6UKr5AKKMBFA3OgnIZElgMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticei75Ylga68F7G98z2V4CY2lzrEa6Zpp51hKzbbkIFBBoMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexRQyItHSwPgCz3ri52bCtTkSz_obihxbY7EBAZM2q_45NcMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45password_45ps8TpFHa75prKWw0XuKklEl5rzRoSVOMU9_45qddTBIQMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backXCu3wIDpcQgSxAbCnGgyp0NHengcskU64eKXzt32oiwMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergefuhm1gfF_BCmHtBaP8xBwcNb5c0ct7652BbnmOdxhUkMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexxgZsmvK7zpPiE8YKmvRT_pL8lNurJCIjMHHvH8p40fMMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93hww30Hmy_45iPbEQfto3BqkJZ7GKiYLJO1D_45kSr_453iJLQMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youbAVGP_3rDG0ZUiUiZsga8EDkPwjwxf6NORGZ89JUqtIMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as indexQTsErAwRzqgJappeewjx_VBVzIgPzN08qnBujTYkeekMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexiPcD5uVnEjzQWeyAA__z0_45PvKXvRnzn3Xepz0a7Wp8kMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93FUy7sVgQdoxiJJsvJkUx7c0Qq5Aqk4GRIr1r_45j6xzO0Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexa0Rjrx6v6KYLzJeXnJ0qyEauXTsEHeZyiNyZMkZ_45IbwMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexKNzw1VOg0hzz10614uSF0Hfg6dFQxowSTXVFd0o7oyYMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93NWIr1EVVr1gwU9ynz5SR9dUQ9yLDXB_4536hKYlNNC1DcMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackjA7uGKIPstY6npMx_45YKh05oqCSwwooGxUYA9Y5Hut1QMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimgfW4dmS56rZljh_4a28IATN_zd4m_INUjB0ZGuH9oxYMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as indexxzKk_45a06wanX_45r5t0X_45DKUEUHV1JiKaqyxIGprKS8y8Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youTgyz5bbZk9f4qVepJtmuGrHtEiOdz3UhTPvQjWxgOxQMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errorItbrnB3beuWbpcbnuQZ9PCC34cQcCQDZkizHxgZ2t2cMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexmUtlFnuVNC94pMJKMoKdll87dKTiGqBpHUbdFulk1HwMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indexjL0CBXoqS6kN0qrOi8uyCczStmZQyJpi1hgQWCe7m0YMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as errorIXZCJqPBRXMvn3xjHQ_45If44Has_Y2uAkJx2Sko_bXxgMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93VArBkTpvuiMXwvngebQD6qpZzGh6qOGpn6wP81sYa5AMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsZsQ891I5KBClgXbprv4PZNRAVLaIXZEIRCftyYjqb1AMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45ratesZEsg93MzmjPPw5TumroCSzIVyonUsX7fOHD_glAo_H4Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93opCGqw39oOfsM5bCZcbtCO2C8PYL84XNKQHkv_4594I4kMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexZjsazrRUzLVmBmLvC1_454Xe__98z6P9mK_45DXKBL1WRjMMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settings82HiVGUIOF85hCK7LuaastXW3WE3BKJ_NgBRLHPA61oMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexEvYG0CQhIs7BHuprJbHLvUx6fyXTp2gXwoEX0h5Ct1sMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chat_MkPfRJlbySSbhgCCyONjq3E9h6_3R5sxUGLsM2D2b4Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45sale_45k0FqY1mBHpF_45cxxNJ1ZluWvwz9M7X1gI1N8fOQAdA0Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youGLjVobjndxmZulWfvfywq3V8Zm_ZUN0Te40V7_45rcIFUMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensive2Jj8W_39GSRxj028bNPp3NOuKdxtYqEZlpNFfJtGarwMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_937q3HXZV1xxe33CdyiEC_bS7w8toa2P0l6ZFo_45ZDpp0MMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as add2YRm8lMQRF_Tg8gsh6MycoO2XndogG235XtcBr8Gl_MMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexmCV1kErX1X8aW00HVeEOYSyQRnlXjkDzdXWdqmNiWnIMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93RS2yK4fU50GfRFoP0oOCXEHVGGpBzwxJNfvkwKXIsUsMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbackWQjY69e9Fae3CYbY8_45OdqNMSzme_UrJ6NimgPCeB_45d8Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93CEfTJmPvfr6CXTZDssXML2SskB9eBhevPU5vNS_glgUMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youX9zhLhcCRXdoO1JyqvPBoZB2HQQpF9Kv9vdumacQVJgMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45buRK_45XZTxtadSG1g2MfbTrZobqNVJVgWoJO_45ag4bHOKIMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indextaxNAzNZBEaNxm7HpOzKi_Qw3RnqJVrMORlRt8v7B8QMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93tzEhu2LYP3bDwwlZCmIJOWnNPjW3rkQmzsBjbgESW0AMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93tov8oG7Q_45VBmhORcKu5QlF5ZuHuIA50h2cttzwXVROgMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexDWNHadUs5txZCtMgFgxM515AxyqdUIL0XSrmUIN9Gg4Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addp2KbQ1r7o57_45KBTBIV2fsh_45_45lQnyDC3PUB_45obVSeXlUMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93sSNYEPaZ0USk7ujBle4_ipQ_45AkzALgg_OQt9I099VncMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailsFUDPCnxUuFxLb9e__VGGI8ZkLjiAJNylEDHUzWb0sA0Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as digital_45eic0mNGDenfICw_bw23u5ct46BbYj1hlPn9W1nPlSm_45t3sMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45youdSGu3ymn8dKjcUDbMOoGqX_45cWaf1_8o3MJBTNZDZLmsMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93Iu4aA4cgKuWoraOGuPTdSCQjHUqEYG3ogKy72AYAD_45kMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93fg8Kwbd7qKpLO9KvWclhyrZD8ibNYWgzLEC_45SS7Un_458Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as step0saFH7bOjwgmcxdvwcv49EJVs0anxS_uq3GbRyMaHyfcMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue?macro=true";
import { default as detailsgU4sdGrBRlo9uZfh9tcZgzDfpB_45wgmEdliQmdwsHPxcMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsaC0VSBe94eyskROwl3Sn26vBSh7fHbtM73_Bh_VGdF0Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexyLdDuGocLVpQ_453ssWGv1HfDMpK_MRuomDB2pkyFpYeEMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signupRyMT8zHKR_n1GQQ26OdgM3ny3vJsve9zX1QTcW6IhgkMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youfq3dUypQbFuhbfVVeAO8kngU72OJF0qcObFP0ylBCUsMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93NbZM2P6rM38P_45TFs4fOlXNYo1zUmcWxXMaBVOyCQLI8Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as _91step_93C4bFmqoBQG6sRByEpW0AMB_M6zRKnxkewOh_45n9T5UoQMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93Dmd6nOl2QHIiau9zkibKD2uptiTDVvnd5sf7JlCc88oMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0yafdatJLOB_45sNyrZHNqRK6edRgYUqWgWtCYPB0B2DIMMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customershkfge1JPsi3XwNCcCv9O5KMH9hy6H4cAcEOiGETIyiAMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youivM_45FeYHVRVz81xU0ps86JN8orKnI_45Xu4C8p78kiNsgMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailsID_RimjWTVnq_DtsJQcVbUhiNpbGCs87QS9d64rJnscMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as detailsDfMaZvlgAHnoSsUhoPAu0dff0sGgAb1_g3hIlR_45crXUMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_93V2kAbp6dlHs_45zhMo_qxFxgzUbU54XRmIW1q69zSNYWEMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebate_AQwSAG9jZu_45bRpIsFahbFL2G2BRnQyzw5D1rI1ugfYMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93mpwugQRpxUjSLFjBr3KYGgYUvKJyIS_45wvm7SrTKDK3IMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsZFCtLjzUxc2cJBH1077RKFZHa7DgrJgor58W6Lj6no4Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0DRKoFY5QZRa5liC4c6i06FjDDIAsdMSzHZp2JhA3x14Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as index5uxU95i4JogA743IiSJbngigs7DEGSQeI4V_FooOFD8Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexc3CzUcuBSKNuoz5vRP8ARNlf8P8aJFRcL78QJZKrvWQMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailstJU8ASfOozDGQTEpl19aai40nRpSEcvp8Iyek0d2prUMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionsdrSRYRcH4kYMPfSfvRXdwXLZj_cBNso4CWLVFrZ8yOIMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as index5U6gb1b77DSdxaD5hWRpr3aPg58sqdZ0LHk0fxLK8pkMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as details6sJ3Fa94yGDaUdoDbccIqcj61V_45hyXznPQCnP6h5DPAMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexN5mPGN61_omeuCQudaGdlWsztmFVCKJdu8ZPxMkCPq8Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successFNiCsXHEYWTcHmORpIgSmOYEniNaZJ9_Nlu5GBtR5CcMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offeraYmkzAvCAEzwksDGf_45Resga3bsNwAM8muLuFrgmBOWYMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailscQaK6eXtrBUEKhc7oU_ZQEHedocS5mUjwf89cE50qnkMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbourhpPuTrRtFb29SRW0VIAzjjKvRrnKQx7TUwG4GDFbG9sMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93dZb8UfrDlFA4Kj0_45QtObVlCoK3uXthATk8jKz5CMBHMMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexvfNy9_450OhkkuqswRzkg6StxlSxFmM7g0HgTjF0l3pIgMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93uononc0_fEoAs9GNPokWU2vMrpVhvgPhzGpD7T29tDcMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexq5LhCGgTjLWBGfyionfqOgjOxPGYkCT3RBx9ZQzqgAcMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_93OF8JAonWUWQqyUOEXJqqZ51O_8DAWzZfjvEn2EPAJ_45oMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as indexjnxN_45p9I_KEfMaMYzKe5iM0c_oxeC7NoNl7DJGRtVmwMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexPmwYFnMfQnEFfGDrfR9kEATyKVwVRcpYalfXAgb2uQwMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsW8LEHn60wS_wz_45VDE2dJ_45NNcE32lOqfzSVkqDsiFhCYMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expiredDm2L9fOIxaYa1_0qgLUARv4lnvngIRAGijfAE1Ef0c8Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as index6ky5_45_456CbSCQibhS2DjbE3URDeK98HeTDkBMtHCHirsMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youazzrpPE6QS0i0teh_45xRKJbWG2mA5rTz8jjNPY1VHabwMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailso6NzDYSFzVuIlv_jn4pklmJapopAYHmuW5y4lr52QYcMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as confirmation91FEhdFP_452_45jzAG0jkRXN1O_YvYhNNhAWRSrKeOe2MIMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionGEPXwiJsJL6LIAJSWjWnsvamUG_45J5tlh2v38BmHfS0MMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultsK2gKn8ZLxM3e5AMCxLG_4_45YWCNjBnw9xNR_45_45J9mqtBgMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationedMMWJnGklTfeTlbmF1STwtIoukXeDXM8DQ8Sabm_45tgMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93DGVA3JhWanvuTYLt0CtP2SeG6Za5WVFmsRlVw9m9wjsMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93b1_45uKsWB5t8cTCN_kBVB6OeAsgqraww7OxFOfN6GHIoMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93CB4AYZ8U_6pKhJEUyeNSkys_oo0GDNOJn8_45HkFh6_45_45wMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93tQmBLg1_8iTmTRC9N_hm8QexlwxaEw4Yo2VnLBwPRlwMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as indexyhGep9gh7IFIS22qc12ZxPNQolAKm1WDPDcECfb27U4Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailspPD0qVTeuZiQE2CmtqUg9NfxzAcCjWk61faNR2kzsZIMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_936x00PxB4YCb1fFIYUuYF4jTFjc3DX6Fo6sNts6oYaAUMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_93DK7HYyEZ9TquqqZhObLBK_CEbiqNskzjwY_alXyxGm4Meta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as index7AX4xx9jbgeesPDBPlHrswLi87MmMFgFx2HAczPsthcMeta } from "/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginhFVSR4yJ2vU_PC_8I8wGnuyemKqx3tQOq8RiGUWu1KgMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerDT7119TeO11861oZ1_RXKCoCn5sStQ107fZkvMSAfvIMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordqfruejslmA6Jd_45a7FsYKHCHSAMFhQPCe17tlgEdNyAYMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: index7afHpBXT_3_nldUoVq2CShwKYdsyH2Yivw36e_45fPNpYMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordtur_45gAOOFK_XLYaY2d0jFKnoUJ9PIFvWdf9g26yVCkIMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45password_45ps8TpFHa75prKWw0XuKklEl5rzRoSVOMU9_45qddTBIQMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93hww30Hmy_45iPbEQfto3BqkJZ7GKiYLJO1D_45kSr_453iJLQMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexa0Rjrx6v6KYLzJeXnJ0qyEauXTsEHeZyiNyZMkZ_45IbwMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexxzKk_45a06wanX_45r5t0X_45DKUEUHV1JiKaqyxIGprKS8y8Meta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexjL0CBXoqS6kN0qrOi8uyCczStmZQyJpi1hgQWCe7m0YMeta?.props ?? {"verificationNeeded":true},
    meta: indexjL0CBXoqS6kN0qrOi8uyCczStmZQyJpi1hgQWCe7m0YMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settings82HiVGUIOF85hCK7LuaastXW3WE3BKJ_NgBRLHPA61oMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexEvYG0CQhIs7BHuprJbHLvUx6fyXTp2gXwoEX0h5Ct1sMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbackWQjY69e9Fae3CYbY8_45OdqNMSzme_UrJ6NimgPCeB_45d8Meta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-form-step0",
    path: "/life-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebate_AQwSAG9jZu_45bRpIsFahbFL2G2BRnQyzw5D1rI1ugfYMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailstJU8ASfOozDGQTEpl19aai40nRpSEcvp8Iyek0d2prUMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offeraYmkzAvCAEzwksDGf_45Resga3bsNwAM8muLuFrgmBOWYMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailscQaK6eXtrBUEKhc7oU_ZQEHedocS5mUjwf89cE50qnkMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93dZb8UfrDlFA4Kj0_45QtObVlCoK3uXthATk8jKz5CMBHMMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionGEPXwiJsJL6LIAJSWjWnsvamUG_45J5tlh2v38BmHfS0MMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93DGVA3JhWanvuTYLt0CtP2SeG6Za5WVFmsRlVw9m9wjsMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93b1_45uKsWB5t8cTCN_kBVB6OeAsgqraww7OxFOfN6GHIoMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93tQmBLg1_8iTmTRC9N_hm8QexlwxaEw4Yo2VnLBwPRlwMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailspPD0qVTeuZiQE2CmtqUg9NfxzAcCjWk61faNR2kzsZIMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_936x00PxB4YCb1fFIYUuYF4jTFjc3DX6Fo6sNts6oYaAUMeta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_93DK7HYyEZ9TquqqZhObLBK_CEbiqNskzjwY_alXyxGm4Meta || {},
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src2409327330/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]